import Cookies from "universal-cookie";
import store from './store';

export function isNullOrUndefined(val) {
  return val === null || typeof val === "undefined";
}

export function countryFlag(cc) {
  if(isNullOrUndefined(cc)) { cc="eu"; } else { cc = cc.toLowerCase(); }
  let ccs = ["ae","al","am","an","ao","ar","at","au","aw","az","bb","bd","be","bg",
  "bh","bm","br","bs","bz","ca","ch","cn","co","cr","cu","cy","cz","de",
  "dj","dk","do","ee","eg","es","eu","fi","fr","gb","ge","gr","hk","hr",
  "hu","id","ie","il","im","in","is","it","jm","jo","jp","kg","kh","kr",
  "kz","lb","lu","lv","ma","md","me","mo","mt","mx","my","nl","no","pa",
  "ph","pl","pt","qa","ro","rs","ru","sa","se","sg","si","sk","th","tr",
  "tw","ua","us","uy","uz","ve","vn","za"];
  if(ccs.filter((t)=>{return t == cc}).length>0) {
    return `/assets/img/ccs/${cc}.svg`;
  } else {
    return `/assets/img/ccs/eu.svg`;
  }
}


export function handleLogout() {
  removeCookie("auth");
  localStorage.removeItem("siteways-bearer");
}

export function capWords(tStr) {
  try {
    return tStr.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
  } catch(ex) {}
  return tStr;
}

export function formatMoney(number,cur) {
  return number.toLocaleString('en-US', { style: 'currency', currency: cur });
}

export function HasRole(role) {
  const state = store.getState();
  const {User} = state.Website;
  let sRole = User.Roles.filter((t) => {return t.toUpperCase() === role.toUpperCase() || t.toUpperCase() === "SUPERADMIN"});
  if(sRole.length>0) {
    return true;
  }
  return false;
}

export function padZero(t) {
  if(t<=9) {return "0" + t};
  return t;
}

export function fieldDate(dte) {
  if(isNullOrUndefined(dte)) return "1970-01-01";
  try {
    let date = new Date(dte);
    return `${date.getFullYear()}-${padZero(date.getMonth()+1)}-${padZero(date.getDate())}`;
  } catch(ex) {}
  return "1970-01-01";
}
export function fieldTime(dte) {
  if(isNullOrUndefined(dte)) return "17:00";
  try {
    let date = new Date(dte);
    return `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
  } catch(ex) {}
  return "17:00";
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function CountryByCode(cde) {
  const state = store.getState();
  cde = cde.toUpperCase();
  if(state.Cache && state.Cache.Data) {
    if(state.Cache.Data.Country) {
      const rec = state.Cache.Data.Country.filter((t) => {
        return t.Value === cde
      });
      if(rec.length) {return rec[0].DisplayValue}
    }
  }
  return "";
}

export function isNullOrEmpty(obj) {
  if(typeof obj === "undefined") { return true;}
  if(obj === null) { return true;}
  if(typeof obj === "string") { return obj === "" }
  return false;
}

export function getSubdomain(hostname) {
  let prts = hostname.split(".");
  while (prts.length > 2) {
    prts.shift();
  }
  return prts.join(".");
}

export function getCookie(key) {
  const cookies = new Cookies();
  return cookies.get(key);
}

export function setCookie(key, value, options) {
  const cookies = new Cookies();
  cookies.set(key, value, options);
}

export function removeCookie(key) {
  const cookies = new Cookies();
  let domain = "localhost"
  if(window.location.hostname !== "localhost") {
    domain = "." + getSubdomain(window.location.hostname);
  }
  console.log("Domain",domain);
  cookies.remove(key, { path: "/", domain: domain });
}

export function getPerPage() {
  try {
    let pp = localStorage.getItem(getPerPageKey());
    if (isNumeric(pp)) {
      return parseInt(pp);
    }
  } catch (ex) {
  }
  return 25;
}

export function getPerPageKey() {
  let url = new URL(window.location.href);

  let file = url.pathname;
  file = replaceAll("/", "-", file);
  file = replaceAll("#", "-", file);
  return `Siteways-PerPage-${file}`;
}

export function setPerPage(pp) {
  try {
    localStorage.setItem(getPerPageKey(),""+pp);
  } catch(ex) {
  }
}

//Mask A Phone Number With * Char Or Any Etc..
export function maskPhone(phn,last,char="*") {
  let iStr = '+0123456789 ';
  let iChr = Array.from(phn);
  last = iChr.length - last;
  let iRtn = "";
  iChr.map((v,i)=>{
    if(iStr.indexOf(v) >= 0) {
      if(v===" ") {
        iRtn += v;
        last--;
      } else {
        if(i >= last) {
          iRtn += char;
        } else {
          iRtn += v;
        }
      }
    }
    return null;
  });
  return iRtn;
}

//Get The Referring URL Using The Browser window.location
export function getReferer() {
  let referer = document.location.toString();
  if(referer.indexOf("localhost")>=0 || referer.indexOf("10.0.0.")>=0) {
    referer = "https://admin.topescort.com" + document.location.pathname + document.location.search;
  }
  return referer;
}

//Get The Users Culture From The Browser
export function getBrowserCulture() {
  let language;
  if (window.navigator.languages) {
    language = window.navigator.languages[0];
  } else {
    language = window.navigator.userLanguage || window.navigator.language;
  }
  return language.split("-")[0];
}

//Replace All Occurrences Of A String In A String
export function replaceAll(rW,wW,rep) {
  let tStr = rep;
  while(tStr.indexOf(rW)>=0) {
    tStr = tStr.replace(rW, wW)
  }
  return tStr;
}

export function getParameterByName(name, url, def) {
  if(typeof def === "undefined") { def = null;}
  if (typeof window === "undefined" && !url) { return def; }
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return def;
  if (!results[2]) return def;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getLoginBearer() {
  let resp = getCookie("auth");
  if(isNullOrUndefined(resp)) {
    let ls = localStorage.getItem("siteways-bearer");
    if(!isNullOrUndefined(ls)) {
      let tok = JSON.parse(ls).access_token;
      return tok;
    }
  } else { return resp; }
}

export function trimStr(tStr,amt) {
  if(isNullOrUndefined(tStr)) {return "";}
  if(tStr.length>amt) {
    return tStr.substr(0,amt-3) + "...";
  } else {
    return tStr;
  }
}
export function hasRole(role,user) {
  return user.Roles.filter((t)=> {return t === role.toUpperCase() || t === "SUPERADMIN"}).length>0
}

export function getTimer() {
  return encodeURIComponent(Math.round((new Date()).getTime() / 1000));
}

export function YesNo(val) {
  if(val) {return "Yes"} else {return "No";}
}
