import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {connect} from 'react-redux';
import * as wActs from '../../../actions/website';

class Login extends Component {

  handleLogin(e) {

    e.preventDefault();
    //console.log(this.Username);
    this.props.dispatch(wActs.login({Username: this.Username.value, Password: this.Password.value}));
  }
  handleForgot(e) {
    e.preventDefault();
    this.props.dispatch(wActs.showWarning("WARN", "Coming soon", 5000));
  }
  handleRegister(e) {
    e.preventDefault();
    this.props.dispatch(wActs.showWarning("WARN", "Coming soon", 5000));
  }

  handlePress(evt) {
    if(evt.key === 'Enter') {
      this.handleLogin(evt);
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onKeyPress={(e) => {this.handlePress(e)}} innerRef={(ref) => {this.Username = ref}} type="text" placeholder="Username" autoComplete="username" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onKeyPress={(e) => {this.handlePress(e)}} innerRef={(ref) => {this.Password = ref}} type="password" placeholder="Password" autoComplete="current-password" />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button onClick={(e) => {this.handleLogin(e)}} color="primary" className="px-4">Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button onClick={(e) => {this.handleForgot(e)}} color="link" className="px-0">Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>You can optionally register. Please contact some admin or click below</p>
                      <Button  onClick={(e) => {this.handleRegister(e)}} color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    WebPage: state.Website,
  }
};
export default connect(mapStateToProps)(Login);



