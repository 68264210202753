import {combineReducers} from "redux";

//Reducers
import Website from "./website";
import Profiles from "./profiles";
import Domains from "./domains";
import Cache from "./cache";
import Media from "./media";
import Users from "./users";
import Blogs from "./blogs";
import Billing from "./billing";
import Mailings from "./mailings";
import Agencies from "./agencies";


export default combineReducers({
  Website, Profiles, Users, Domains, Cache, Media, Blogs, Billing, Mailings, Agencies
});
