
//GLOBAL CONST'S/SETTINGS
export const APIUrl = "https://app.swinity.com/Api";
export const ADMUrl = "https://adminapp.swinity.com/Api";
export const TOKEN_URL = 'https://oauth.swinity.com/connect/token';
export const WebsiteVersion = "2.70.65";


//WEBSITE ACTIONS
export const ACTION_LOADWEBSITE_PENDING = "ACTION_LOADWEBSITE_PENDING";
export const ACTION_LOADWEBSITE_SUCCESS = "ACTION_LOADWEBSITE_SUCCESS";
export const ACTION_LOADWEBSITE_FAILED = "ACTION_LOADWEBSITE_FAILED";
export const SHOW_WARNING = "SHOW_WARNING";
export const HIDE_WARNING = "HIDE_WARNING";
export const ACTION_FETCH_LOGIN_PENDING = "FETCH_LOGIN_PENDING";
export const ACTION_FETCH_LOGIN_FULFILLED = "FETCH_LOGIN_FULFILLED";
export const ACTION_FETCH_LOGIN_REJECTED = "FETCH_LOGIN_REJECTED";
export const GET_DATA = "GETTING_DATA";
export const GOT_DATA = "GOT_DATA";
export const POSTED_APPLICATION = "POSTED_APPLICATION";


//CACHE ACTIONS
export const ACTION_FETCH_CACHE_PENDING = "FETCH_CACHE_PENDING";
export const ACTION_FETCH_CACHE_SUCCESS = "FETCH_CACHE_SUCCESS";
export const ACTION_FETCH_CACHE_FAILED = "FETCH_CACHE_FAILED";
export const FETCH_USERFILTERS_PENDING = "FETCH_USERFILTERS_PENDING";
export const FETCH_USERFILTERS_SUCCESS = "FETCH_USERFILTERS_SUCCESS";
export const FETCH_USERFILTERS_FAILED = "FETCH_USERFILTERS_FAILED";
export const FETCH_APPS_PENDING = "FETCH_APPS_PENDING";
export const FETCH_APPS_SUCCESS = "FETCH_APPS_SUCCESS";
export const FETCH_APPS_FAILED = "FETCH_APPS_FAILED";
export const FETCH_ROLES_PENDING = "FETCH_ROLES_PENDING";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILED = "FETCH_ROLES_FAILED";



//DASH DATA
export const FETCH_DASH_PENDING = "FETCH_DASH_PENDING";
export const FETCH_DASH_SUCCESS = "FETCH_DASH_SUCCESS";
export const FETCH_DASH_FAILED = "FETCH_DASH_FAILED";


//DOMAIN/WEBSITE ACTIONS
export const ACTION_FETCH_DOMAINS_PENDING = "FETCH_DOMAINS_PENDING";
export const ACTION_FETCH_DOMAINS_SUCCESS = "FETCH_DOMAINS_SUCCESS";
export const ACTION_FETCH_DOMAINS_FAILED = "FETCH_DOMAINS_FAILED";
export const ACTION_FETCH_DOMAIN_PENDING = "FETCH_DOMAIN_PENDING";
export const ACTION_FETCH_DOMAIN_SUCCESS = "FETCH_DOMAIN_SUCCESS";
export const ACTION_FETCH_DOMAIN_FAILED = "FETCH_DOMAIN_FAILED";
export const ACTION_SAVE_DOMAIN_PENDING = "SAVE_DOMAIN_PENDING";
export const ACTION_SAVE_DOMAIN_SUCCESS = "SAVE_DOMAIN_SUCCESS";
export const ACTION_SAVE_DOMAIN_FAILED = "SAVE_DOMAIN_FAILED";
export const ACTION_FETCH_SLUGS_PENDING = "FETCH_SLUGS_PENDING";
export const ACTION_FETCH_SLUGS_FAILED = "FETCH_SLUGS_FAILED";
export const ACTION_FETCH_SLUGS_SUCCESS = "FETCH_SLUGS_SUCCESS";
export const ACTION_FETCH_HTMLBLOCKS_PENDING = "FETCH_HTMLBLOCKS_PENDING";
export const ACTION_FETCH_HTMLBLOCKS_FAILED = "FETCH_HTMLBLOCKS_FAILED";
export const ACTION_FETCH_HTMLBLOCKS_SUCCESS = "FETCH_HTMLBLOCKS_SUCCESS";
export const ACTION_FETCH_HTMLBLOCK_PENDING = "FETCH_HTMLBLOCK_PENDING";
export const ACTION_FETCH_HTMLBLOCK_FAILED = "FETCH_HTMLBLOCK_FAILED";
export const ACTION_FETCH_HTMLBLOCK_SUCCESS = "FETCH_HTMLBLOCK_SUCCESS";
export const ACTION_UPDATE_HTMLBLOCK_PENDING = "UPDATE_HTMLBLOCK_PENDING";
export const ACTION_UPDATE_HTMLBLOCK_FAILED = "UPDATE_HTMLBLOCK_FAILED";
export const ACTION_UPDATE_HTMLBLOCK_SUCCESS = "UPDATE_HTMLBLOCK_SUCCESS";


//MEDIA ACTIONS
export const ACTION_FETCH_MEDIA_PENDING = "FETCH_MEDIA_PENDING";
export const ACTION_FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const ACTION_FETCH_MEDIA_FAILED = "FETCH_MEDIA_FAILED";
export const ACTION_LATEST_UPLOAD = "FETCH_MEDIA_PENDING";

export const ACTION_UPLOAD_MEDIA_PENDING = "UPLOAD_MEDIA_PENDING";
export const ACTION_UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const ACTION_UPLOAD_MEDIA_FAILED = "UPLOAD_MEDIA_FAILED";
export const ACTION_UPLOAD_CREATIVE_PENDING = "UPLOAD_CREATIVE_PENDING";
export const ACTION_UPLOAD_CREATIVE_SUCCESS = "UPLOAD_CREATIVE_SUCCESS";
export const ACTION_UPLOAD_CREATIVE_FAILED = "UPLOAD_CREATIVE_FAILED";
export const ACTION_DELETE_LINK_PENDING = "DELETE_LINK_PENDING";
export const ACTION_DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS";
export const ACTION_DELETE_LINK_FAILED = "DELETE_LINK_FAILED";
export const ACTION_DELETE_MEDIA_PENDING = "DELETE_MEDIA_PENDING";
export const ACTION_DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const ACTION_DELETE_MEDIA_FAILED = "DELETE_MEDIA_FAILED";
export const ACTION_DELETE_PLACEMENT_PENDING = "DELETE_PLACEMENT_PENDING";
export const ACTION_DELETE_PLACEMENT_SUCCESS = "DELETE_PLACEMENT_SUCCESS";
export const ACTION_DELETE_PLACEMENT_FAILED = "DELETE_PLACEMENT_FAILED";
export const ACTION_FETCH_MEDIAREC_PENDING = "FETCH_MEDIAREC_PENDING";
export const ACTION_FETCH_MEDIAREC_SUCCESS = "FETCH_MEDIAREC_SUCCESS";
export const ACTION_FETCH_MEDIAREC_FAILED = "FETCH_MEDIAREC_FAILED";
export const ACTION_FETCH_CREATIVES_PENDING = "FETCH_CREATIVES_PENDING";
export const ACTION_FETCH_CREATIVES_SUCCESS = "FETCH_CREATIVES_SUCCESS";
export const ACTION_FETCH_CREATIVES_FAILED = "FETCH_CREATIVES_FAILED";
export const ACTION_FETCH_PLACEMENTS_PENDING = "FETCH_PLACEMENTS_PENDING";
export const ACTION_FETCH_PLACEMENTS_SUCCESS = "FETCH_PLACEMENTS_SUCCESS";
export const ACTION_FETCH_PLACEMENTS_FAILED = "FETCH_PLACEMENTS_FAILED";
export const ACTION_FETCH_PLACEMENT_PENDING = "FETCH_PLACEMENT_PENDING";
export const ACTION_FETCH_PLACEMENT_SUCCESS = "FETCH_PLACEMENT_SUCCESS";
export const ACTION_FETCH_PLACEMENT_FAILED = "FETCH_PLACEMENT_FAILED";
export const ACTION_SET_PLACEMENTS_CREATIVE = "ACTION_SET_PLACEMENTS_CREATIVE";
export const ACTION_FETCH_CREATIVE_PENDING = "FETCH_CREATIVE_PENDING";
export const ACTION_FETCH_CREATIVE_SUCCESS = "FETCH_CREATIVE_SUCCESS";
export const ACTION_FETCH_CREATIVE_FAILED = "FETCH_CREATIVE_FAILED";
export const ACTION_FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";
export const ACTION_FETCH_ZONES_FAILED = "FETCH_ZONES_FAILED";
export const ACTION_FETCH_ZONES_PENDING = "FETCH_ZONES_PENDING";
export const ACTION_FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const ACTION_FETCH_CHANNELS_FAILED = "FETCH_CHANNELS_FAILED";
export const ACTION_FETCH_CHANNELS_PENDING = "FETCH_CHANNELS_PENDING";
export const ACTION_FETCH_LINKS_SUCCESS = "FETCH_LINKS_SUCCESS";
export const ACTION_FETCH_LINKS_FAILED = "FETCH_LINKS_FAILED";
export const ACTION_FETCH_LINKS_PENDING = "FETCH_LINKS_PENDING";
export const ACTION_FETCH_LINK_SUCCESS = "FETCH_LINK_SUCCESS";
export const ACTION_FETCH_LINK_FAILED = "FETCH_LINK_FAILED";
export const ACTION_FETCH_LINK_PENDING = "FETCH_LINK_PENDING";

export const ACTION_FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const ACTION_FETCH_CAMPAIGNS_FAILED = "FETCH_CAMPAIGNS_FAILED";
export const ACTION_FETCH_CAMPAIGNS_PENDING = "FETCH_CAMPAIGNS_PENDING";
export const ACTION_FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const ACTION_FETCH_COUNTRIES_FAILED = "FETCH_COUNTRIES_FAILED";
export const ACTION_FETCH_COUNTRIES_PENDING = "FETCH_COUNTRIES_PENDING";
export const ACTION_FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const ACTION_FETCH_EVENTS_FAILED = "FETCH_EVENTS_FAILED";
export const ACTION_FETCH_EVENTS_PENDING = "FETCH_EVENTS_PENDING";
export const ACTION_FETCH_SUBMITLINK_SUCCESS = "FETCH_SUBMITLINK_SUCCESS";
export const ACTION_FETCH_SUBMITLINK_FAILED = "FETCH_SUBMITLINK_FAILED";
export const ACTION_FETCH_SUBMITLINK_PENDING = "FETCH_SUBMITLINK_PENDING";
export const ACTION_FETCH_SUBMITLINK_CLEARRELOAD = "FETCH_SUBMITLINK_CLEARRELOAD";
export const ACTION_FETCH_LINKEMBED_SUCCESS = "FETCH_LINKEMBED_SUCCESS";
export const ACTION_FETCH_LINKEMBED_FAILED = "FETCH_LINKEMBED_FAILED";
export const ACTION_FETCH_LINKEMBED_PENDING = "FETCH_LINKEMBED_PENDING";
export const ACTION_FETCH_LINKEMBED_RENDER = "FETCH_LINKEMBED_RENDER";
export const ACTION_LINKCHECKBACKLINK_SUCCESS = "LINKCHECKBACKLINK_SUCCESS";
export const ACTION_LINKCHECKBACKLINK_FAILED = "LINKCHECKBACKLINK_FAILED";
export const ACTION_LINKCHECKBACKLINK_PENDING = "LINKCHECKBACKLINK_PENDING";



//STAT ACTIONS
export const FETCH_STATS_PENDING = "FETCH_STATS_PENDING";
export const FETCH_STATS_SUCCESS = "FETCH_STATS_SUCCESS";
export const FETCH_STATS_FAILED = "FETCH_STATS_FAILED";



//USER ACTIONS
export const FETCH_USERS_PENDING = "FETCH_USERS_PENDING";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";
export const FETCH_USERROLES_PENDING = "FETCH_USERROLES_PENDING";
export const FETCH_USERROLES_SUCCESS = "FETCH_USERROLES_SUCCESS";
export const FETCH_USERROLES_FAILED = "FETCH_USERROLES_FAILED";
export const FETCH_USERAPPS_PENDING = "FETCH_USERAPPS_PENDING";
export const FETCH_USERAPPS_SUCCESS = "FETCH_USERAPPS_SUCCESS";
export const FETCH_USERAPPS_FAILED = "FETCH_USERAPPS_FAILED";



//BLOG ACTIONS
export const FETCH_BLOGPOSTS_PENDING = "FETCH_BLOGPOSTS_PENDING";
export const FETCH_BLOGPOSTS_SUCCESS = "FETCH_BLOGPOSTS_SUCCESS";
export const FETCH_BLOGPOSTS_FAILED = "FETCH_BLOGPOSTS_FAILED";
export const FETCH_BLOGPOST_PENDING = "FETCH_BLOGPOST_PENDING";
export const FETCH_BLOGPOST_SUCCESS = "FETCH_BLOGPOST_SUCCESS";
export const FETCH_BLOGPOST_FAILED = "FETCH_BLOGPOST_FAILED";


//TRANSACTIONS ACTIONS
export const FETCH_TRANSACTIONS_PENDING = "FETCH_TRANSACTIONS_PENDING";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_FAILED = "FETCH_TRANSACTIONS_FAILED";
export const FETCH_BILLERS_PENDING = "FETCH_BILLERS_PENDING";
export const FETCH_BILLERS_SUCCESS = "FETCH_BILLERS_SUCCESS";
export const FETCH_BILLERS_FAILED = "FETCH_BILLERS_FAILED";
export const FETCH_ADDINVOICE_PENDING = "FETCH_ADDINVOICE_PENDING";
export const FETCH_ADDINVOICE_SUCCESS = "FETCH_ADDINVOICE_SUCCESS";
export const FETCH_ADDINVOICE_FAILED = "FETCH_ADDINVOICE_FAILED";


//MAILINGS ACTIONS
export const FETCH_MAILINGS_PENDING = "FETCH_MAILINGS_PENDING";
export const FETCH_MAILINGS_SUCCESS = "FETCH_MAILINGS_SUCCESS";
export const FETCH_MAILINGS_FAILED = "FETCH_MAILINGS_FAILED";
export const FETCH_MAILINGSLIST_PENDING = "FETCH_MAILINGSLIST_PENDING";
export const FETCH_MAILINGSLIST_SUCCESS = "FETCH_MAILINGSLIST_SUCCESS";
export const FETCH_MAILINGSLIST_FAILED = "FETCH_MAILINGSLIST_FAILED";
export const CLONE_MAILINGS_PENDING = "CLONE_MAILINGS_PENDING";
export const CLONE_MAILINGS_SUCCESS = "CLONE_MAILINGS_SUCCESS";
export const CLONE_MAILINGS_FAILED = "CLONE_MAILINGS_FAILED";
export const FETCH_MAILING_PENDING = "FETCH_MAILING_PENDING";
export const FETCH_MAILING_SUCCESS = "FETCH_MAILING_SUCCESS";
export const FETCH_MAILING_FAILED = "FETCH_MAILING_FAILED";
export const ACTION_SAVEMAILING_PENDING = "ACTION_SAVEMAILING_PENDING";
export const ACTION_SAVEMAILING_SUCCESS = "ACTION_SAVEMAILING_SUCCESS";
export const ACTION_SAVEMAILING_FAILED = "ACTION_SAVEMAILING_FAILED";
export const ACTION_SENDMAILING_PENDING = "ACTION_SENDMAILING_PENDING";
export const ACTION_SENDMAILING_SUCCESS = "ACTION_SENDMAILING_SUCCESS";
export const ACTION_SENDMAILING_FAILED = "ACTION_SENDMAILING_FAILED";

//System Actions
export const FETCH_SERVERSTATUS_PENDING = "FETCH_SERVERSTATUS_PENDING";
export const FETCH_SERVERSTATUS_SUCCESS = "FETCH_SERVERSTATUS_SUCCESS";
export const FETCH_SERVERAPISTATUS_SUCCESS = "FETCH_SERVERAPISTATUS_SUCCESS";
export const FETCH_SERVERSTATUS_FAILED = "FETCH_SERVERSTATUS_FAILED";
export const FETCH_BADIPS_PENDING = "FETCH_BADIPS_PENDING";
export const FETCH_BADIPS_SUCCESS = "FETCH_BADIPS_SUCCESS";
export const FETCH_BADIPS_FAILED = "FETCH_BADIPS_FAILED";
export const FETCH_RULES_PENDING = "FETCH_RULES_PENDING";
export const FETCH_RULES_SUCCESS = "FETCH_RULES_SUCCESS";
export const FETCH_RULES_FAILED = "FETCH_RULES_FAILED";
export const FETCH_REDIRECTS_PENDING = "FETCH_REDIRECTS_PENDING";
export const FETCH_REDIRECTS_SUCCESS = "FETCH_REDIRECTS_SUCCESS";
export const FETCH_REDIRECTS_FAILED = "FETCH_REDIRECTS_FAILED";
export const FETCH_CRONS_PENDING = "FETCH_CRONS_PENDING";
export const FETCH_CRONS_SUCCESS = "FETCH_CRONS_SUCCESS";
export const FETCH_CRONS_FAILED = "FETCH_CRONS_FAILED";
export const FETCH_TEMPSITES_PENDING = "FETCH_TEMPSITES_PENDING";
export const FETCH_TEMPSITES_SUCCESS = "FETCH_TEMPSITES_SUCCESS";
export const FETCH_TEMPSITES_FAILED = "FETCH_TEMPSITES_FAILED";
export const ACTION_ADDRULE_PENDING = "ACTION_ADDRULE_PENDING";
export const ACTION_ADDRULE_SUCCESS = "ACTION_ADDRULE_SUCCESS";
export const ACTION_ADDRULE_FAILED = "ACTION_ADDRULE_FAILED";
export const ACTION_DELETERULE_PENDING = "ACTION_DELETERULE_PENDING";
export const ACTION_DELETERULE_SUCCESS = "ACTION_DELETERULE_SUCCESS";
export const ACTION_DELETERULE_FAILED = "ACTION_DELETERULE_FAILED";

export const ACTION_DELETETEMPSITE_PENDING = "ACTION_DELETETEMPSITE_PENDING";
export const ACTION_DELETETEMPSITE_SUCCESS = "ACTION_DELETETEMPSITE_SUCCESS";
export const ACTION_DELETETEMPSITE_FAILED = "ACTION_DELETETEMPSITE_FAILED";

export const ACTION_INSERT_BACKLINK = "ACTION_INSERT_BACKLINK";
export const ACTION_UPDATE_BACKLINK = "ACTION_INSERT_BACKLINK";

