import React, { Component } from 'react';
import {connect} from 'react-redux'
import * as wActs from '../actions/website';

class Warnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message,
      level: "INFO", // INFO,WARN,ERROR
      onClose: props.onClose,
    }
    this.timer = null;
    this.audInfo = new Audio("/assets/info.mp3");this.audInfo.load();
    this.audWarn = new Audio("/assets/warn.mp3");this.audWarn.load();
    this.audError = new Audio("/assets/error.mp3");this.audError.load();

  }
  onClose(e) {
    this.props.dispatch(wActs.hideWarning());
  }
  color() {
    switch(this.props.WebPage.Warning.Level.toUpperCase()) {
      case "INFO": {
        return "success";
      }
      case "WARN": {
        return "warning";
      }
      case "ERROR": {
        return "danger";
      }
      default: {return "success"}
    }
  }
  sound() {
    switch(this.props.WebPage.Warning.Level.toUpperCase()) {
      case "INFO": {
        return this.audInfo.play();
      }
      case "WARN": {
        return this.audWarn.play();
      }
      case "ERROR": {
        return this.audError.play();
      }
      default: {return "/assets/info.mp3"}
    }
  }

  render() {
    const active = this.props.WebPage.Warning.Showing ? "active" : "";

    if(active==="active") {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.props.dispatch(wActs.hideWarning());
      }, this.props.WebPage.Warning.Timer)
      //this.sound();
    }
    const color = this.color();
    return (
        <React.Fragment>
        {this.props.WebPage.Warning.Showing &&
            <div style={{zIndex: "9999", position: "fixed",bottom: "0px",right: "15px",minWidth: "200px"}} className={`animated fadeIn alert alert-${color} alert-dismissible fade ${this.props.WebPage.Warning.Showing ? "show" : ""}`} role="alert">
              <button type="button" style={{outline: "none"}} className="close" aria-label="Close" onClick={(e) => {this.onClose(e)}}><span aria-hidden="true">×</span></button>
              {this.props.WebPage.Warning.Message}
            </div>
        }
        </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    WebPage: state.Website
  }
}
export default connect(mapStateToProps)(Warnings);
