import * as wConst from '../const/website';
const initState = {
  Mailings: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  CloneMail: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  Mailing: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  MailingsList: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  SendMail: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  }
};
export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.FETCH_MAILING_PENDING: {
      state = {...state, Mailing: {...state.Mailing, Loaded: false}};
      break;
    }
    case wConst.FETCH_MAILING_FAILED: {
      state = {...state, Mailing: {...state.Mailing, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_MAILING_SUCCESS: {
      state = {...state, Mailing: {...state.Mailing, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_MAILINGS_PENDING: {
      state = {...state, Mailings: {...state.Mailings, Loaded: false}};
      break;
    }
    case wConst.FETCH_MAILINGS_FAILED: {
      state = {...state, Mailings: {...state.Mailings, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_MAILINGS_SUCCESS: {
      state = {...state, Mailings: {...state.Mailings, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_MAILINGSLIST_PENDING: {
      state = {...state, MailingsList: {...state.MailingsList, Loaded: false}};
      break;
    }
    case wConst.FETCH_MAILINGSLIST_FAILED: {
      state = {...state, MailingsList: {...state.MailingsList, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_MAILINGSLIST_SUCCESS: {
      state = {...state, MailingsList: {...state.MailingsList, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.CLONE_MAILINGS_PENDING: {
      state = {...state, CloneMail: {...state.CloneMail, Loaded: false}};
      break;
    }
    case wConst.CLONE_MAILINGS_FAILED: {
      state = {...state, CloneMail: {...state.CloneMail, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.CLONE_MAILINGS_SUCCESS: {
      state = {...state, CloneMail: {...state.CloneMail, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_SENDMAILING_PENDING: {
      state = {...state, SendMail: {...state.SendMail, Loaded: false}};
      break;
    }
    case wConst.ACTION_SENDMAILING_FAILED: {
      state = {...state, SendMail: {...state.SendMail, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_SENDMAILING_SUCCESS: {
      state = {...state, SendMail: {...state.SendMail, Loaded: true, ApiResult: action.payload}};
      break;
    }
    default: {}
  }
  return state;
}

