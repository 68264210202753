import * as wConst from '../const/website';
const initState = {
  Loaded: false,
  Warning: {
    Level: "INFO", Message: "", Showing: false, Timer: 10000
  },
  Dash: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"},
  },
  Stats: {

  },
  BadIps: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"},
  },
  Crons: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"},
  },
  TempSites: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"},
  },
  Rules: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"},
  },
  Redirects: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"},
  },
  GettingData: false,
  SystemInfo: {
    Loaded: false,
    Application: {Code: 500, Message: "No Message", Result: {}},
    ApiInfo: {Code: 500, Message: "No Message", Result: {}},
    ServerInfo: {Code: 500, Message: "No Message", Result: {}},
  }
};

export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.GET_DATA: {
      state = {...state, GettingData: true};
      break;
    }
    case wConst.GOT_DATA: {
      state = {...state, GettingData: false};
      break;
    }
    case wConst.POSTED_APPLICATION: {
      state = {...state, SystemInfo: {...state.SystemInfo, Application: action.payload}};
      break;
    }
    case wConst.ACTION_LOADWEBSITE_PENDING: {
      state = {...state, Loaded: false};
      break;
    }
    case wConst.ACTION_LOADWEBSITE_FAILED: {
      state = {...state, Loaded: false};
      break;
    }
    case wConst.ACTION_LOADWEBSITE_SUCCESS: {
      state = {...state, ...action.payload, Loaded: true};
      break;
    }
    case wConst.SHOW_WARNING: {
      state = {...state, Warning: {Level: action.payload.Level, Message: action.payload.Message, Showing: true, Timer: action.payload.Timer}};
      break;
    }
    case wConst.HIDE_WARNING: {
      state = {...state, Warning: {...state.Warning, Showing: false}};
      break;
    }
    case wConst.FETCH_DASH_PENDING: {
      state = {...state, Dash: {...state.Dash, Loaded: false}};
      break;
    }
    case wConst.FETCH_DASH_FAILED: {
      state = {...state, Dash: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_DASH_SUCCESS: {
      state = {...state, Dash: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_STATS_PENDING: {
      state = {...state};
      state.Stats[action.payload.Id] = {Loaded: false, ApiResult: ""};
      break;
    }
    case wConst.FETCH_STATS_FAILED: {
      state = {...state};
      state.Stats[action.payload.Id] = {Loaded: true, ApiResult: action.payload};
      break;
    }
    case wConst.FETCH_STATS_SUCCESS: {
      state = {...state};
      state.Stats[action.payload.Id] = {Loaded: true, ApiResult: action.payload};
      break;
    }
    case wConst.FETCH_SERVERSTATUS_PENDING: {
      state = {...state, SystemInfo: {...state.SystemInfo, Loaded: false}};
      break;
    }
    case wConst.FETCH_SERVERSTATUS_FAILED: {
      state = {...state, SystemInfo: {...action.payload, Loaded: true}};
      break;
    }
    case wConst.FETCH_SERVERSTATUS_SUCCESS: {
      state = {...state, SystemInfo: {...action.payload, Loaded: true}};
      break;
    }
    case wConst.FETCH_SERVERAPISTATUS_SUCCESS: {
      state = {...state, SystemInfo: {...state.SystemInfo, ApiInfo: action.payload, Loaded: true}};
      break;
    }
    case wConst.FETCH_BADIPS_PENDING: {
      state = {...state, BadIps: {...state.BadIps, Loaded: false}};
      break;
    }
    case wConst.FETCH_BADIPS_FAILED: {
      state = {...state, BadIps: {...state.BadIps, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_BADIPS_SUCCESS: {
      state = {...state, BadIps: {...state.BadIps, Loaded: true, ApiResult: action.payload}};
      break;
    }

    case wConst.FETCH_RULES_PENDING: {
      state = {...state, Rules: {...state.Rules, Loaded: false}};
      break;
    }
    case wConst.FETCH_RULES_FAILED: {
      state = {...state, Rules: {...state.Rules, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_RULES_SUCCESS: {
      state = {...state, Rules: {...state.Rules, Loaded: true, ApiResult: action.payload}};
      break;
    }

    case wConst.FETCH_REDIRECTS_PENDING: {
      state = {...state, Redirects: {...state.Redirects, Loaded: false}};
      break;
    }
    case wConst.FETCH_REDIRECTS_FAILED: {
      state = {...state, Redirects: {...state.Redirects, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_REDIRECTS_SUCCESS: {
      state = {...state, Redirects: {...state.Redirects, Loaded: true, ApiResult: action.payload}};
      break;
    }

    case wConst.FETCH_CRONS_PENDING: {
      state = {...state, Crons: {...state.Crons, Loaded: false}};
      break;
    }
    case wConst.FETCH_CRONS_FAILED: {
      state = {...state, Crons: {...state.Crons, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_CRONS_SUCCESS: {
      state = {...state, Crons: {...state.Crons, Loaded: true, ApiResult: action.payload}};
      break;
    }

    case wConst.FETCH_TEMPSITES_PENDING: {
      state = {...state, TempSites: {...state.Crons, Loaded: false}};
      break;
    }
    case wConst.FETCH_TEMPSITES_FAILED: {
      state = {...state, TempSites: {...state.TempSites, Loaded: false, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_TEMPSITES_SUCCESS: {
      state = {...state, TempSites: {...state.TempSites, Loaded: true, ApiResult: action.payload}};
      break;
    }


  }
  return state;
}


