import * as wConst from '../const/website';
const initState = {
  DomainList: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  Domain: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  SaveDomain: {
    Saving: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  PageSlugs: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  HtmlBlocks: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  HtmlBlock: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New", Result: {}}
  }

};

export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.ACTION_FETCH_DOMAINS_PENDING: {
      state = {...state, DomainList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_DOMAINS_FAILED: {
      state = {...state, DomainList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_DOMAINS_SUCCESS: {
      state = {...state, DomainList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_DOMAIN_PENDING: {
      state = {...state, Domain: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_DOMAIN_FAILED: {
      state = {...state, Domain: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_DOMAIN_SUCCESS: {
      state = {...state, Domain: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_SAVE_DOMAIN_PENDING: {
      state = {...state, SaveDomain: {Saving: true}};
      break;
    }
    case wConst.ACTION_SAVE_DOMAIN_FAILED: {
      state = {...state, SaveDomain: {Saving: false, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_SAVE_DOMAIN_SUCCESS: {
      state = {...state, SaveDomain: {Saving: false, ApiResult: action.payload}, Domain: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_SLUGS_PENDING: {
      state = {...state, PageSlugs: {...state.PageSlugs, Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_SLUGS_FAILED: {
      state = {...state, PageSlugs: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_SLUGS_SUCCESS: {
      state = {...state, PageSlugs: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_HTMLBLOCKS_PENDING: {
      state = {...state, HtmlBlocks: {...state.HtmlBlocks, Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_HTMLBLOCKS_FAILED: {
      state = {...state, HtmlBlocks: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_HTMLBLOCKS_SUCCESS: {
      state = {...state, HtmlBlocks: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_HTMLBLOCK_PENDING: {
      state = {...state, HtmlBlock: {...state.HtmlBlock, Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_HTMLBLOCK_FAILED: {
      state = {...state, HtmlBlock: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_HTMLBLOCK_SUCCESS: {
      state = {...state, HtmlBlock: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    default: {}
  }
  return state;
}


