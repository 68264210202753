import * as wConst from "../const/website"
import * as utils from "../utils"

export function getWebsite() {
  return (dispatch, getState) => {
    dispatch({type: wConst.ACTION_LOADWEBSITE_PENDING, payload: true});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    }

    dispatch(getApps());
    dispatch(getRoles());
    fetch(`${wConst.APIUrl}/context?include=settings`, {headers: headers})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code === 200) {

          /* TODO Add Logic For Login And Role Checking Against data.User */
          if(data.Result.User.UserId !==0 && data.Result.User.Roles.filter((t)=> {return t === "ADMIN" || t === "SYSTEM"}).length<=0) {
            utils.handleLogout(5000);
            dispatch(showWarning("ERROR", "Can not use admin, roles missing"));
            data.Result.User.UserId = 0; //THIS WILL FORCE LOGIN AGAIN :)
          }

          /* CACHE The List Types */
          fetch(`${wConst.ADMUrl}/list/all`, {headers: headers})
            .then(resp => resp.json())
            .then(function (cdata) {
              if(data.Code === 200) {
                dispatch({type: wConst.ACTION_FETCH_CACHE_SUCCESS, payload: cdata});
                //OK Site Loaded, Show The Page Etc....
                dispatch({type: wConst.ACTION_LOADWEBSITE_SUCCESS, payload: data.Result});
              } else {
                dispatch({type: wConst.ACTION_FETCH_CACHE_FAILED, payload: cdata});
              }
            }).catch(function (ex) {
              dispatch({type: wConst.ACTION_FETCH_CACHE_FAILED, payload: {Code: 500, Message: ex.toString()}});
              return;
            });
        } else {
          dispatch({type: wConst.ACTION_LOADWEBSITE_FAILED, payload: data.Message});
        }
      }).catch(function (ex) {
        dispatch({type: wConst.ACTION_LOADWEBSITE_FAILED, payload: ex.toString()});
        return;
      });
  }
}

export function getApps() {
  return (dispatch) => {
    dispatch({type: wConst.FETCH_APPS_PENDING, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/Applications/Names`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code === 200) {
          dispatch({type: wConst.FETCH_APPS_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_APPS_FAILED, payload: data});
        }
      }).catch(function (ex) {
      dispatch({type: wConst.FETCH_APPS_FAILED, payload: {Code: 500, Message: "Internal error getting dash info"}});
    });
  }
}

export function getRoles() {
  return (dispatch) => {
    dispatch({type: wConst.FETCH_ROLES_PENDING, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/Roles`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code === 200) {
          dispatch({type: wConst.FETCH_ROLES_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_ROLES_FAILED, payload: data});
        }
      }).catch(function (ex) {
        dispatch({type: wConst.FETCH_ROLES_FAILED, payload: {Code: 500, Message: "Internal error getting dash info"}});
      });
  }
}


export function getStats(type="sales",period="last30Days", sDate=null, eDate=null) {
  return (dispatch) => {
    dispatch({type: wConst.FETCH_STATS_PENDING, payload: {Id:`${type}-${period}`}});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    let qry = "";
    if(sDate !== null) {
      qry += "&start=" + sDate;
    }
    if(eDate !== null) {
      qry += "&end=" + eDate;
    }
    fetch(`${wConst.ADMUrl}/Dashboard/Stats?type=${type}&period=${period}${qry}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code === 200) {
          data.Id = `${type}-${period}`;
          dispatch({type: wConst.FETCH_STATS_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_STATS_FAILED, payload: data});
        }
      }).catch(function (ex) {
        dispatch({type: wConst.FETCH_STATS_FAILED, payload: {Code: 500, Message: "Internal error getting dash info"}});
    });
  }
}

export function getDashInfo() {
  return (dispatch) => {
    dispatch({type: wConst.FETCH_DASH_PENDING, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/dashboard`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code === 200) {
          dispatch({type: wConst.FETCH_DASH_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_DASH_FAILED, payload: data});
        }
      }).catch(function (ex) {
        dispatch({type: wConst.FETCH_DASH_FAILED, payload: {Code: 500, Message: "Internal error getting dash info"}});
    });
  }
}


export function login(props) {
  return (dispatch) => {
    dispatch({ type: wConst.ACTION_FETCH_LOGIN_PENDING, payload: true });
    let bdy = "grant_type=password&username=" + encodeURIComponent(props.Username) + "&password=" + encodeURIComponent(props.Password) + "&client_id=escort&client_secret=simplesecret";

    fetch(`${wConst.TOKEN_URL}`, {
      headers: {
        'X-Alt-Referer': utils.getReferer(),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'POST',
      body: bdy
    })
      .then(resp => resp.json())
      .then(function (data) {
        console.log("data", data);
        if (data.error) {
          dispatch({ type: wConst.ACTION_FETCH_LOGIN_REJECTED, payload: "User/Pass Failed" });
          dispatch(showWarning("WARN","Failed to login..."));
        } else {
          let domain = window.location.toString().indexOf("localhost") ? "localhost" : "." + utils.getSubdomain(window.location.hostname);
          dispatch(showWarning("INFO","Logging in"), 5000);
          dispatch({ type: wConst.ACTION_FETCH_LOGIN_FULFILLED, payload: "" });
          localStorage.setItem("siteways-bearer", JSON.stringify(data)); //Setup Bearer For Context Call :)
          utils.setCookie("auth", data.access_token, { path: "/", maxAge: 31557600, domain: domain });
          window.location.reload(false);
        }
      })
      .catch(function (ex) {
        //API Token Just Returns "invalid_username_or_password" As Message, Need Translate?
        dispatch({ type: wConst.ACTION_FETCH_LOGIN_REJECTED, payload: "Internal error..." });
        dispatch(showWarning("ERROR","Internal error..."));
      });
  };
}

export function getUserFilters() {
  return (dispatch,getState) => {
    let finalData = {};
    let headers = {
      'X-Alt-Referer': utils.getReferer(),
    };

    dispatch({type: wConst.FETCH_USERFILTERS_PENDING, payload: false});
    fetch(`${wConst.ADMUrl}/list/userFilter`, {headers: headers})
      .then(resp => resp.json())
      .then(function (fildata) {
        finalData.UserFilters = fildata.Result;
        fetch(`${wConst.ADMUrl}/list/userType`, {headers: headers})
          .then(resp => resp.json())
          .then(function (typedata) {
            finalData.UserTypes = typedata.Result;
            fetch(`${wConst.ADMUrl}/list/userSortOrder`, {headers: headers})
              .then(resp => resp.json())
              .then(function (sortdata) {
                finalData.UserSortOrder = sortdata.Result;
                dispatch({type: wConst.FETCH_USERFILTERS_SUCCESS, payload: finalData});
              })
              .catch(function (ex) {
                console.log("Failed to get userSortOrder",ex);
                dispatch({type: wConst.FETCH_USERFILTERS_FAILED, payload: {Code: 500, Message: ex.toString()}});
              });
          })
          .catch(function (ex) {
            console.log("Failed to get userTypes",ex);
            dispatch({type: wConst.FETCH_USERFILTERS_FAILED, payload: {Code: 500, Message: ex.toString()}});
          });
      })
      .catch(function (ex) {
        console.log("Failed to get userFilters",ex);
        dispatch({type: wConst.FETCH_USERFILTERS_FAILED, payload: {Code: 500, Message: ex.toString()}});
      });
  }
}

export function GetBadIps(type="impression") {
  return (dispatch,getState) => {
    if(!getState().Website.BadIps.Loaded) {
      dispatch({type: wConst.FETCH_BADIPS_PENDING, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/Firewall/Misbehaving_Ips?type=${type}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (apiData) {
        dispatch({type: wConst.FETCH_BADIPS_SUCCESS, payload: apiData});
      }).catch((ex) => {
        console.log("Error getting server bad ips: " + ex.toString());
        dispatch({type: wConst.FETCH_BADIPS_FAILED, payload: {Code: 500, Message: "Error Getting Ips"}});
      });
  }
}


export function AddRule(dta) {
  return (dispatch) => {
    dispatch({type: wConst.ACTION_ADDRULE_PENDING, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer(),
      'Content-type': 'application/json'
    };
    fetch(`${wConst.ADMUrl}/Firewall/Rules?ip=${dta.ip}&days=${dta.days}&mode=${dta.mode}&notes=${encodeURIComponent(dta.notes)}`, {headers: headers, method: "POST"})
      .then(resp => resp.json())
      .then(function (data) {
        let lvl = "INFO";
        if(data.Code == "200") {
          dispatch({type: wConst.ACTION_ADDRULE_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.ACTION_ADDRULE_FAILED, payload: data});
          lvl = "WARN";
        }
        dispatch(showWarning(lvl,data.Message));
      }).catch((ex) => {
        dispatch({type: wConst.ACTION_ADDRULE_FAILED, payload: {Code: 500, Message: "Internal error: " + ex.toString()}});
        dispatch(showWarning("ERROR","Internal error: " + ex.toString()));
      });
  }
}

export function DeleteRule(id) {
  return (dispatch) => {
    dispatch({type: wConst.ACTION_DELETERULE_PENDING, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer(),
    };
    fetch(`${wConst.ADMUrl}/Firewall/Rules?id=${id}`, {headers: headers, method: "DELETE"})
      .then(resp => resp.json())
      .then(function (data) {
        let lvl = "INFO";
        if(data.Code == "200") {
          dispatch({type: wConst.ACTION_DELETERULE_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.ACTION_DELETERULE_FAILED, payload: data});
        }
        dispatch(showWarning(lvl,data.Message));
      }).catch((ex) => {
        dispatch({type: wConst.ACTION_DELETERULE_FAILED, payload: {Code: 500, Message: "Internal error: " + ex.toString()}});
        dispatch(showWarning("ERROR","Internal error: " + ex.toString()));
    });
  }
}

export function DeleteTempSite(id,purge) {
  return (dispatch) => {
    dispatch({type: wConst.ACTION_DELETETEMPSITE_PENDING, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer(),
    };
    fetch(`${wConst.ADMUrl}/sites${purge ? "/purge" : ""}?id=${id}`, {headers: headers, method: "DELETE"})
      .then(resp => resp.json())
      .then(function (data) {
        let lvl = "INFO";
        if(data.Code == "200") {
          dispatch({type: wConst.ACTION_DELETETEMPSITE_SUCCESS, payload: data});
        } else {
          lvl="WARN"
          dispatch({type: wConst.ACTION_DELETETEMPSITE_FAILED, payload: data});
        }
        dispatch(showWarning(lvl,data.Message));
      }).catch((ex) => {
        dispatch({type: wConst.ACTION_DELETETEMPSITE_FAILED, payload: {Code: 500, Message: "Internal error: " + ex.toString()}});
        dispatch(showWarning("ERROR","Internal error: " + ex.toString()));
      });
  }
}


export function GetCrons(pge,pgeS) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Crons.Loaded) {
      dispatch({type: wConst.FETCH_CRONS_PENDING, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    if(utils.isNullOrUndefined(pge)) { pge="1"; }
    if(utils.isNullOrUndefined(pgeS)) { pgeS=utils.getPerPage(); }
    let q = `?page=${pge}&pageSize=${pgeS}`;
    fetch(`${wConst.ADMUrl}/cronjobs${q}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.FETCH_CRONS_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_CRONS_FAILED, payload: data});
        }
      }).catch((ex) => {
      console.log("Error getting server cron jobs: " + ex.toString());
      dispatch({type: wConst.FETCH_CRONS_FAILED, payload: {Code: 500, Message: "Error Getting Ips"}});
    });
  }
}

export function GetTempSites(pge,pgeS,term,sort,filter) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.TempSites.Loaded) {
      dispatch({type: wConst.FETCH_TEMPSITES_PENDING, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    if(utils.isNullOrUndefined(pge)) { pge="1"; }
    if(utils.isNullOrUndefined(pgeS)) { pgeS=utils.getPerPage(); }

    let q = `?page=${pge}&pageSize=${pgeS}`;
    if(!utils.isNullOrEmpty(term)) {
      q += `&term=${term}`;
    }
    if(!utils.isNullOrEmpty(sort)) {
      q += `&sort=${sort}`;
    }
    if(!utils.isNullOrEmpty(filter)) {
      q += `&filter=${filter}`;
    }

    fetch(`${wConst.ADMUrl}/sites${q}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.FETCH_TEMPSITES_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_TEMPSITES_FAILED, payload: data});
        }
      }).catch((ex) => {
      console.log("Error getting server cron jobs: " + ex.toString());
      dispatch({type: wConst.FETCH_TEMPSITES_FAILED, payload: {Code: 500, Message: "Error Getting Ips"}});
    });
  }
}


export function ToggleRobots(id,allow,cb) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Rules.Loaded) {
      dispatch({type: wConst.GET_DATA, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/domains/${id}/Robots?status=${allow ? "allow" : "disallow"}`, {headers: headers, method: "POST"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.GOT_DATA, payload: true});
          cb(data);
        } else {
          dispatch({type: wConst.GOT_DATA, payload: false});
        }
      }).catch((ex) => {
        dispatch({type: wConst.GOT_DATA, payload: true});
      });
  }
}

export function PublishSitemap(id,cb) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Rules.Loaded) {
      dispatch({type: wConst.GET_DATA, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/domains/${id}/publish_sitemap`, {headers: headers, method: "POST"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.GOT_DATA, payload: true});
          cb(data);
        } else {
          dispatch({type: wConst.GOT_DATA, payload: false});
        }
      }).catch((ex) => {
        dispatch({type: wConst.GOT_DATA, payload: true});
      });
  }
}

export function CleanSitemap(id,cb) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Rules.Loaded) {
      dispatch({type: wConst.GET_DATA, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/domains/${id}/clean_sitemap`, {headers: headers, method: "POST"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.GOT_DATA, payload: true});
          cb(data);
        } else {
          dispatch({type: wConst.GOT_DATA, payload: false});
        }
      }).catch((ex) => {
      dispatch({type: wConst.GOT_DATA, payload: true});
    });
  }
}

export function BuildPages(id,cb) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Rules.Loaded) {
      dispatch({type: wConst.GET_DATA, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    fetch(`${wConst.ADMUrl}/domains/${id}/build_pages`, {headers: headers, method: "POST"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.GOT_DATA, payload: true});
          cb(data);
        } else {
          dispatch({type: wConst.GOT_DATA, payload: false});
        }
      }).catch((ex) => {
      dispatch({type: wConst.GOT_DATA, payload: true});
    });
  }
}

export function AddRedirect(rec,cb) {
  return (dispatch) => {
    dispatch({type: wConst.GET_DATA, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer(),
    };
    fetch(`${wConst.ADMUrl}/redirects/create?source=${encodeURIComponent(rec.source)}&target=${encodeURIComponent(rec.target)}&resetAll=${rec.resetAll}`, {headers: headers, method: "POST"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == "200") {
          dispatch(showWarning("INFO",data.Message));
        } else {
          dispatch(showWarning("WARN",data.Message));
        }
        dispatch({type: wConst.GOT_DATA, payload: false});
        if(typeof cb == "function") {
          cb(data);
        }
      }).catch((ex) => {
      dispatch({type: wConst.GOT_DATA, payload: false});
      dispatch(showWarning("ERROR","Internal error: " + ex.toString()));
    });
  }
}


export function DeleteRedirect(id,purge,cb) {
  return (dispatch) => {
    dispatch({type: wConst.GET_DATA, payload: false});
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer(),
    };
    fetch(`${wConst.ADMUrl}/redirects${purge ? "/purge" : "" }?id=${id}`, {headers: headers, method: "DELETE"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == "200") {
          dispatch(showWarning("INFO",data.Message));
        } else {
          dispatch(showWarning("WARN",data.Message));
        }
        dispatch({type: wConst.GOT_DATA, payload: false});
        if(typeof cb == "function") {
          cb(data);
        }
      }).catch((ex) => {
      dispatch({type: wConst.GOT_DATA, payload: false});
      dispatch(showWarning("ERROR","Internal error: " + ex.toString()));
    });
  }
}

export function GetRedirects(pge,pgeS,term,fil,sort) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Redirects.Loaded) {
      dispatch({type: wConst.FETCH_REDIRECTS_PENDING, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    if(utils.isNullOrUndefined(pge)) { pge="1"; }
    if(utils.isNullOrUndefined(pgeS)) { pgeS=utils.getPerPage(); }
    let q = `?page=${pge}&pageSize=${pgeS}`;
    if(!utils.isNullOrEmpty(term)) { q += `&term=${encodeURIComponent(term)}`; }
    if(!utils.isNullOrEmpty(fil)) { q += `&filter=${encodeURIComponent(fil)}`; }
    if(!utils.isNullOrEmpty(sort)) { q += `&sort=${encodeURIComponent(sort)}`; }

    fetch(`${wConst.ADMUrl}/redirects${q}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.FETCH_REDIRECTS_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_REDIRECTS_FAILED, payload: data});
        }
      }).catch((ex) => {
      console.log("Error getting server redirects: " + ex.toString());
      dispatch({type: wConst.FETCH_REDIRECTS_FAILED, payload: {Code: 500, Message: "Error Getting Ips"}});
    });
  }
}



export function GetRules(pge,pgeS,term,fil,sort) {
  return (dispatch,getState) => {
    let ste = getState();
    if(!ste.Website.Rules.Loaded) {
      dispatch({type: wConst.FETCH_RULES_PENDING, payload: false});
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };
    if(utils.isNullOrUndefined(pge)) { pge="1"; }
    if(utils.isNullOrUndefined(pgeS)) { pgeS=utils.getPerPage(); }
    let q = `?page=${pge}&pageSize=${pgeS}`;
    if(!utils.isNullOrEmpty(term)) { q += `&term=${encodeURIComponent(term)}`; }
    if(!utils.isNullOrEmpty(fil)) { q += `&filter=${encodeURIComponent(fil)}`; }
    if(!utils.isNullOrEmpty(sort)) { q += `&sort=${encodeURIComponent(sort)}`; }

    fetch(`${wConst.ADMUrl}/Firewall/Rules${q}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (data) {
        if(data.Code == 200) {
          dispatch({type: wConst.FETCH_RULES_SUCCESS, payload: data});
        } else {
          dispatch({type: wConst.FETCH_RULES_FAILED, payload: data});
        }
      }).catch((ex) => {
      console.log("Error getting server rules: " + ex.toString());
      dispatch({type: wConst.FETCH_RULES_FAILED, payload: {Code: 500, Message: "Error Getting Ips"}});
    });
  }
}


export function GetApiServerStatus(key, all, cb) {
  return (dispatch,getState) => {
    if(!getState().Website.SystemInfo.Loaded) {
      dispatch({type: wConst.FETCH_SERVERSTATUS_PENDING, payload: false});
    }
    if(all) {
      dispatch(showWarning("INFO","Please wait...",2000));
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };

    let cData = getState().SystemInfo;

    fetch(`/api/proxy/apistatus?svrkey=${key}&all=${all}`, {headers: headers, method: "GET"})
      .then((resp) => {
        if(resp.ok) {
          return resp.json();
        } else {
          return {Code: resp.status, Message: resp.statusText}
        }
      })
      .then((apiInfo)=> {
        dispatch({type: wConst.FETCH_SERVERAPISTATUS_SUCCESS, payload: apiInfo });
        if(typeof cb == "function") {
          cb(apiInfo);
        }
      });
  }
}

export function GetServersStatus(all=false) {
  return (dispatch,getState) => {
    if(!getState().Website.SystemInfo.Loaded) {
      dispatch({type: wConst.FETCH_SERVERSTATUS_PENDING, payload: false});
    }
    if(all) {
      dispatch(showWarning("INFO","Please wait...",2000));
    }
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };

    //First Get The API Status Call
    fetch(`${wConst.ADMUrl}/system/status?alwaysReturnOk=true&testAll=${all}`, {headers: headers, method: "GET"})
      .then((resp) => {
        if(resp.ok) {
          return resp.json();
        } else {
          return {Code: resp.status, Message: resp.statusText}
        }
      })
      .then(function (apiData) {
        fetch(`/api/proxy/status`, {headers: headers, method: "GET"})
          .then((resp) => {
            if(resp.ok) {
              return resp.json();
            } else {
              return {Code: resp.status, Message: resp.statusText}
            }
          })
          .then(function (nginxData) {
            fetch(`/api/proxy/connections`, {headers: headers, method: "GET"})

              .then(resp => resp.text())
              .then(function (conText) {
                fetch(`${wConst.ADMUrl}/applications?applicationId=10000`, {headers: headers, method: "GET"})
                  .then((resp) => {
                    if(resp.ok) {
                      return resp.json();
                    } else {
                      return {Code: resp.status, Message: resp.statusText}
                    }
                  })
                  .then(function (appInfo) {
                    if(appInfo.Code != 200) {
                      dispatch(showWarning("ERROR", appInfo.Message));
                    } else {
                      let cData = conText.split("\n");
                      let tmpArr = []; let cInfo = { Connections: 0, Read: 0, Write: 0, Waiting: 0, TotalConnections: 0, TotalRequests: 0  };
                      tmpArr = cData[0].split(" "); cInfo.Connections = parseInt(tmpArr[2]); //2
                      tmpArr = cData[2].split(" "); cInfo.TotalConnections = parseInt(tmpArr[1]); cInfo.TotalRequests = parseInt(tmpArr[3]);
                      tmpArr = cData[3].split(" "); cInfo.Read = parseInt(tmpArr[1]);  cInfo.Write = parseInt(tmpArr[3]); cInfo.Waiting = parseInt(tmpArr[5]);
                      dispatch({type: wConst.FETCH_SERVERSTATUS_SUCCESS, payload: {
                          ApiInfo: apiData,
                          Application: appInfo,
                          ServerInfo: nginxData,
                          ConnectionInfo: cInfo
                        }
                      });
                    }
                  });
              }).catch((cex)=>{
                console.log("Error getting NGinX Info: " + cex.toString());
                dispatch({type: wConst.FETCH_SERVERSTATUS_FAILED, payload: {
                    ApiInfo: apiData,
                    ServerInfo: nginxData,
                }});
                dispatch(showWarning("ERROR", "Internal Error: " + cex.message));
            });
          }).catch((iex)=> {
            console.log("Error getting NGinX Info: " + iex.toString());
            dispatch({type: wConst.FETCH_SERVERSTATUS_FAILED, payload: {
              ApiInfo: apiData,
              ServerInfo: {Code: 500, Message: `Internal Error ${iex.toString()}`, Result: {}},
            }});
            dispatch(showWarning("ERROR", "Internal Error: " + iex.message));
          });
      }).catch((ex) => {
        console.log("Error getting server status from api: " + ex.toString());
        dispatch({type: wConst.FETCH_SERVERSTATUS_FAILED, payload: {
          ApiInfo: {Code: 500, Message: `Internal Error ${ex.toString()}`, Result: {}},
          ServerInfo: {Code: 500, Message: `Never Called`, Result: {}},
        }});
        dispatch(showWarning("ERROR", "Internal Error: " + ex.message));
    });

  }
}




export function PostApplication(dta) {
  return (dispatch,getState) => {
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer(),
      'Content-type': 'application/json'
    };
    fetch(`${wConst.ADMUrl}/applications`, {headers: headers, method: "POST", body: JSON.stringify(dta)})
      .then(resp => resp.json())
      .then(function (dta) {
        if(dta.Code==200) {
          dispatch(showWarning("INFO",dta.Message));
          dispatch({type: wConst.POSTED_APPLICATION, payload: dta});
        } else {
          dispatch(showWarning("WARN",dta.Message));
        }
      }).catch((ex) => {
        dispatch(showWarning("ERROR","Client Internal Error: " + ex.toString()));
      });


  }
}

export function ClearCache(svr) {
  return (dispatch) => {
    const ref = utils.getReferer();
    let headers = {
      'X-Alt-Referer': ref,
      'Authorization': 'Bearer ' + utils.getLoginBearer()
    };

    fetch(`/api/proxy/clearcache?server=${svr}&auth=${utils.getLoginBearer()}`, {headers: headers, method: "GET"})
      .then(resp => resp.json())
      .then(function (dta) {
        if(dta.code==200) {
          dispatch(showWarning("INFO",dta.message));
        } else {
          dispatch(showWarning("WARN",dta.message));
        }
      }).catch((ex) => {
        dispatch(showWarning("ERROR","Client Internal Error: " + ex.toString()));
    });
  }
}




export function showWarning(lvl,msg,tmr) {
  return (dispatch) => {
    dispatch({type: wConst.SHOW_WARNING, payload: {Level: lvl, Message: msg, Timer: utils.isNullOrUndefined(tmr) ? 10000 : tmr}});
  }
}
export function hideWarning() {
  return (dispatch) => {
    dispatch({type: wConst.HIDE_WARNING, payload: null});
  }
}
export const customStylesGrey = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "100"
  }),

  control: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    boxShadow: "none",
    color: "#c8ced3",
    outline: "none"
  }),
  placeholder: (provided,state) => ({
    ...provided,
    fontSize: ".9em",
    fontWeight: "400",
    color: "#c8ced3"
  }),
  singleValue: (provided,state) => ({
    ...provided,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    color: "#c8ced3"
  }),
  multiValueLabel: (provided,state) => ({
    ...provided,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    color: "#c8ced3"
  }),
  multiValueRemove: (provided,state) => {
    return ({
      ...provided,
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    });
  },
};
export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "100"
  }),

  control: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    boxShadow: "none",
  }),
  placeholder: (provided,state) => ({
    ...provided,
    fontSize: ".9em",
    fontWeight: "400"
  }),
  multiValueLabel: (provided,state) => ({
    ...provided,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px"
  }),
  multiValueRemove: (provided,state) => {
    return ({
      ...provided,
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    });
  },
};
export const customStylesErr = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: "100"
  }),

  control: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    borderColor: "Red",
    boxShadow: "none",
    backgroundColor: "#f9efef",
  }),
  placeholder: (provided,state) => ({
    ...provided,
    fontSize: ".9em",
    fontWeight: "400",
    color: "rgb(128, 128, 128)"
  }),
  multiValueLabel: (provided,state) => ({
    ...provided,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px"
  }),
  multiValueRemove: (provided,state) => {
    return ({
      ...provided,
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    });
  },
};
export const editstyles = {
  editor: {
    border: '1px solid gray',
    minHeight: '6em'
  }
};
export const txtStyle = {height: "38px", fontWeight: "normal", fontSize: "1.0em", color: "#222", paddingLeft: "9px", background: "#fff"};
export const errStyle = {height: "38px", fontWeight: "normal", fontSize: "1.0em", color: "rgb(128, 128, 128)", paddingLeft: "9px", borderColor: "Red", backgroundColor: "#f9efef"};
export const errLabelStyle = {fontWeight: "normal", fontSize: ".75em", color: "Red", marginBottom: "4px"};
export const disStyle = {height: "38px", fontWeight: "normal", fontSize: "1.0em", color: "rgb(128, 128, 128)", paddingLeft: "9px", backgroundColor: "rgb(242, 242, 242)"};


export const defaultStatus = [
  {value: 0, label: "Waiting approval", Id: 0},
  {value: 1, label: "Approved", Id: 1},
  {value: 2, label: "Rejected", Id: 2},
];

export const defaultLinkType = [
  {value: 0, label: "Unknown", Id: 0},
  {value: 1, label: "Link", Id: 1},
  {value: 2, label: "Review", Id: 2},
  {value: 3, label: "Page", Id: 3},
];
