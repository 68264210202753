import * as wConst from '../const/website';
const initState = {
  Transactions: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  Billers: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  }
};
export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.FETCH_TRANSACTIONS_PENDING: {
      state = {...state, Transactions: {...state.Transactions, Loaded: false}};
      break;
    }
    case wConst.FETCH_TRANSACTIONS_FAILED: {
      state = {...state, Transactions: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_TRANSACTIONS_SUCCESS: {
      state = {...state, Transactions: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_BILLERS_PENDING: {
      state = {...state, Billers: {...state.Billers, Loaded: false}};
      break;
    }
    case wConst.FETCH_BILLERS_FAILED: {
      state = {...state, Billers: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_BILLERS_SUCCESS: {
      state = {...state, Billers: {Loaded: true, ApiResult: action.payload}};
      break;
    }


    default: {}
  }
  return state;
}

