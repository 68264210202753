import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Warning from "./comps/warning";

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Main styles for this application
import './scss/style.css'
import * as wActs from './actions/website';
// Containers
import { DefaultLayout } from './containers';
import {connect} from 'react-redux';
import { Login } from './views/Pages';
import {Container, Row} from "reactstrap";

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  componentDidMount() {
    this.props.dispatch(wActs.getWebsite());
  }

  render() {
    if(!this.props.WebPage.Loaded) {
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
            <img src={"/assets/img/loader.svg"} style={{width: "100px", height: "100px"}} alt={""}/>
            </Row>
          </Container>
        </div>
      )
    }

    if(this.props.WebPage.User.UserId<=0) {
      return (
      <React.Fragment>
        <HashRouter>
          <Switch>
            <Route path="/" name="Home" component={Login} />
          </Switch>
        </HashRouter>
        <Warning/>
      </React.Fragment>);
    }

    return (
      <React.Fragment>
        <HashRouter>
          <Switch>
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
        <Warning/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { WebPage: state.Website }
}

export default connect(mapStateToProps)(App);
