import React, { Component } from 'react';
import {Button, Nav, Badge} from 'reactstrap';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import * as utils from "../../utils";

import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import sygnet from '../../assets/img/brand/icon.png'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }


  handleLogout(e) {
    e.preventDefault()
    utils.handleLogout();
    setTimeout(() => {
      document.location.reload(true);
    },1000);

  }
  toggle = () => this.setState({isOpen: !this.state.isOpen});

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: this.props.WebPage.Settings.LogoUrl, width: 150, alt: 'CoreUI Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <Button color={"warning"} size={"sm"} className="mr-3" onClick={(e) => {this.handleLogout(e)}}><i className="fa fa-lock"/>&nbsp;Logout</Button>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return ({
    WebPage: state.Website
  });
};

export default connect(mapStateToProps)(DefaultHeader);
