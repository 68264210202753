import * as wConst from '../const/website';
import * as utils from '../utils';

const initState = {
  MediaRecord: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  MediaList: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  Image: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  Video: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  Upload: {
    Uploading: false,
    LastUpload: new Date('14 Jun 2017 00:00:00 PDT'),
    ApiResult: {Code: 500, Message: "New"}
  },
  UploadCreative: {
    Uploading: false,
    LastUpload: new Date('14 Jun 2017 00:00:00 PDT'),
    ApiResult: {Code: 500, Message: "New"}
  },
  CreativesList: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  Creative: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },

  Placements: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  CreativeRecord: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  ZonesList: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  Channels: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  LinksList: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  CampaignsList: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  CountriesList: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  EventsList: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  Placement: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  Link: {
    Loaded: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  LinkSubmission: {
    Submitting: false,
    ReloadLinks: false,
    ApiResult: {Code: 500, Message: "New"}
  },
  LinkEmbed: {
    Loaded: false,
    Render: false
  },
  CheckBackLinks: {
    Loaded: false, Loading: false, ApiResult: {Code: 500, Message: "Not Loaded"}
  }
};

export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.ACTION_INSERT_BACKLINK: {
      state = {...state};
      let o = state.Link.ApiResult.Result; debugger;
      state.Link.ApiResult.Result.Backlinks.splice(0,0,{
        Id: 0, DomainId: action.payload.DomainId
      });
      break;
    }
    case wConst.ACTION_LINKCHECKBACKLINK_PENDING: {
      state = {...state, CheckBackLinks: {...state.CheckBackLinks, Loading: true, Loaded: false}}; break;
    }
    case wConst.ACTION_LINKCHECKBACKLINK_FAILED: {
      state = {...state, CheckBackLinks: {...state.CheckBackLinks, Loading: false, Loaded: true}}; break;
    }
    case wConst.ACTION_LINKCHECKBACKLINK_SUCCESS: {
      state = {...state, CheckBackLinks: {...state.CheckBackLinks, Loading: false, Loaded: true}};
      state.Link.ApiResult.Result.Backlinks = action.payload.Result; //Update Backlinks In Record
      break;
    }
    case wConst.ACTION_SET_PLACEMENTS_CREATIVE: {
      state = {...state, CreativeRecord: {Loaded: true, ApiResult: {Code: 200, Message: "Success", Result: action.payload}}};
      break;
    }
    case wConst.ACTION_FETCH_MEDIA_PENDING: {
      state = {...state, MediaList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_MEDIA_FAILED: {
      state = {...state, MediaList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_MEDIA_SUCCESS: {
      state = {...state, MediaList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_MEDIAREC_PENDING: {
      state = {...state, MediaRecord: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_MEDIAREC_FAILED: {
      state = {...state, MediaRecord: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_MEDIAREC_SUCCESS: {
      state = {...state, MediaRecord: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CREATIVES_PENDING: {
      state = {...state, CreativesList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_CREATIVES_FAILED: {
      state = {...state, CreativesList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CREATIVES_SUCCESS: {
      state = {...state, CreativesList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_UPLOAD_MEDIA_PENDING: {
      state = {...state, Upload: {Uploading: true}};
      break;
    }
    case wConst.ACTION_UPLOAD_MEDIA_FAILED: {
      state = {...state, Upload: {Uploading: false, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_UPLOAD_MEDIA_SUCCESS: {
      state = {...state, Upload: {Uploading: false, ApiResult: action.payload, LastUpload: Date.now()}};
      break;
    }
    case wConst.ACTION_UPLOAD_CREATIVE_PENDING: {
      state = {...state, UploadCreative: {Uploading: true}};
      break;
    }
    case wConst.ACTION_UPLOAD_CREATIVE_FAILED: {
      state = {...state, UploadCreative: {Uploading: false, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_UPLOAD_CREATIVE_SUCCESS: {
      state = {...state, UploadCreative: {Uploading: false, ApiResult: action.payload, LastUpload: Date.now()}};
      break;
    }
    case wConst.ACTION_FETCH_PLACEMENTS_PENDING: {
      state = {...state, Placements: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_PLACEMENTS_FAILED: {
      state = {...state, Placements: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_PLACEMENTS_SUCCESS: {
      state = {...state, Placements: {Loaded: true, ApiResult: action.payload}};
      if(typeof action.payload.Creative !== "undefined") {
        state.CreativeRecord = {Loaded: true, ApiResult: action.payload.Creative};
      }
      if(typeof action.payload.Link !== "undefined") {
        state.Link = {Loaded: true, ApiResult: action.payload.Link};
      }
      break;
    }
    case wConst.ACTION_FETCH_CREATIVE_PENDING: {
      state = {...state, Creative: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_CREATIVE_FAILED: {
      state = {...state, Creative: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CREATIVE_SUCCESS: {
      state = {...state, Creative: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_PLACEMENT_PENDING: {
      state = {...state, Placement: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_PLACEMENT_FAILED: {
      state = {...state, Placement: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_PLACEMENT_SUCCESS: {
      state = {...state, Placement: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_ZONES_PENDING: {
      state = {...state, ZonesList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_ZONES_FAILED: {
      state = {...state, ZonesList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_ZONES_SUCCESS: {
      state = {...state, ZonesList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CHANNELS_PENDING: {
      state = {...state, Channels: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_CHANNELS_FAILED: {
      state = {...state, Channels: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CHANNELS_SUCCESS: {
      state = {...state, Channels: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CAMPAIGNS_PENDING: {
      state = {...state, CampaignsList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_CAMPAIGNS_FAILED: {
      state = {...state, CampaignsList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_CAMPAIGNS_SUCCESS: {
      state = {...state, CampaignsList: {Loaded: true, ApiResult: action.payload}};
      break;
    }

    case wConst.ACTION_FETCH_COUNTRIES_PENDING: {
      state = {...state, CountriesList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_COUNTRIES_FAILED: {
      state = {...state, CountriesList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_COUNTRIES_SUCCESS: {
      state = {...state, CountriesList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_EVENTS_PENDING: {
      state = {...state, EventsList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_EVENTS_FAILED: {
      state = {...state, EventsList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_EVENTS_SUCCESS: {
      state = {...state, EventsList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_LINKS_PENDING: {
      state = {...state, LinksList: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_LINKS_FAILED: {
      state = {...state, LinksList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_LINKS_SUCCESS: {
      state = {...state, LinksList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_LINK_PENDING: {
      state = {...state, Link: {Loaded: false}};
      break;
    }
    case wConst.ACTION_FETCH_LINK_FAILED: {
      state = {...state, Link: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_LINK_SUCCESS: {
      state = {...state, Link: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_SUBMITLINK_PENDING: {
      state = {...state, LinkSubmission: {Submitting: true, ReloadLinks: false, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_SUBMITLINK_FAILED: {
      state = {...state, LinkSubmission: {Submitting: false, ReloadLinks: false, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_SUBMITLINK_SUCCESS: {
      state = {...state, LinkSubmission: {Submitting: false, ReloadLinks: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_SUBMITLINK_CLEARRELOAD: {
      state = {...state, LinkSubmission: {...state.LinkSubmission, ReloadLinks: false}};
      break;
    }
    case wConst.ACTION_FETCH_LINKEMBED_PENDING: {
      state = {...state, LinkEmbed: {Render: false, Loaded: false, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_LINKEMBED_FAILED: {
      state = {...state, LinkEmbed: {Render: false, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.ACTION_FETCH_LINKEMBED_SUCCESS: {
      //debugger;
      state = {...state, LinkEmbed: {Loaded: true, Render: true, ApiResult: action.payload}};
      state.Link.ApiResult.Result.Card.Html = !utils.isNullOrEmpty(action.payload.Result.Html) ? action.payload.Result.Html + "<!-- " + Math.random() * 1000 + " -->" : "";
      break;
    }
    case wConst.ACTION_FETCH_LINKEMBED_RENDER: {
      state = {...state, LinkEmbed: {...state.LinkSubmission, Render: false}};
      break;
    }
    default: {}
  }
  return state;
}



