import * as wConst from '../const/website';
const initState = {
  UserList: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  UserRoles: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  UserApps: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },

};
export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.FETCH_USERS_PENDING: {
      state = {...state, UserList: {Loaded: false}};
      break;
    }
    case wConst.FETCH_USERS_FAILED: {
      state = {...state, UserList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_USERS_SUCCESS: {
      state = {...state, UserList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_USERROLES_PENDING: {
      state = {...state, UserRoles: {Loaded: false}};
      break;
    }
    case wConst.FETCH_USERROLES_FAILED: {
      state = {...state, UserRoles: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_USERROLES_SUCCESS: {
      state = {...state, UserRoles: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_USERAPPS_PENDING: {
      state = {...state, UserApps: {Loaded: false}};
      break;
    }
    case wConst.FETCH_USERAPPS_FAILED: {
      state = {...state, UserApps: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_USERAPPS_SUCCESS: {
      state = {...state, UserApps: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    default: {}
  }
  return state;
}



