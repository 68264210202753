

//List Of Agencies
export const FETCH_AGENCIES_PENDING = "FETCH_AGENCIES_PENDING";
export const FETCH_AGENCIES_SUCCESS = "FETCH_AGENCIES_SUCCESS";
export const FETCH_AGENCIES_FAILED = "FETCH_AGENCIES_FAILED";


//List Of Profiles
export const FETCH_PROFILES_PENDING = "FETCH_PROFILES_PENDING";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_PROFILES_FAILED = "FETCH_PROFILES_FAILED";

//List Of Site Groups
export const FETCH_GROUPS_PENDING = "FETCH_GROUPS_PENDING";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILED = "FETCH_GROUPS_FAILED";

//Client List
export const FETCH_CLIENTLIST_PENDING = "FETCH_CLIENTLIST_PENDING";
export const FETCH_CLIENTLIST_FAILED = "FETCH_CLIENTLIST_FAILED";
export const FETCH_CLIENTLIST_SUCCESS = "FETCH_CLIENTLIST_SUCCESS";


//List Of Profiles In SiteGroup
export const FETCH_GROUPPROFILES_PENDING = "FETCH_GROUPPROFILES_PENDING";
export const FETCH_GROUPPROFILES_SUCCESS = "FETCH_GROUPPROFILES_SUCCESS";
export const FETCH_GROUPPROFILES_FAILED = "FETCH_GROUPPROFILES_FAILED";
export const FETCH_ADDGROUPPROFILE_PENDING = "FETCH_ADDGROUPPROFILE_PENDING";
export const FETCH_ADDGROUPPROFILE_SUCCESS = "FETCH_ADDGROUPPROFILE_SUCCESS";
export const FETCH_ADDGROUPPROFILE_FAILED = "FETCH_ADDGROUPPROFILE_FAILED";
export const FETCH_DELGROUPPROFILE_PENDING = "FETCH_DELGROUPPROFILE_PENDING";
export const FETCH_DELGROUPPROFILE_SUCCESS = "FETCH_DELGROUPPROFILE_SUCCESS";
export const FETCH_DELGROUPPROFILE_FAILED = "FETCH_ADDGROUPPROFILE_FAILED";

export const ACTION_POSTCLIENTLIST_PENDING = "ACTION_POSTCLIENTLIST_PENDING";
export const ACTION_POSTCLIENTLIST_FAILED = "ACTION_POSTCLIENTLIST_FAILED";
export const ACTION_POSTCLIENTLIST_SUCCESS = "ACTION_POSTCLIENTLIST_SUCCESS";

