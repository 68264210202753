export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      roles: "ADMIN"
    },
    {
      name: 'Profiles',
      url: '/profiles',
      icon: 'fa fa-female',
      roles: "PROFILES",
      children: [
        {
          name: 'List',
          url: '/profileslist',
          icon: 'fa fa-female',
          roles: "PROFILES",
        },
        {
          name: 'Groups',
          url: '/profiles/groups',
          icon: 'fa fa-group',
          roles: "PROFILEGROUPS",
        },
        {
          name: 'BlackList',
          url: '/profiles/blacklist',
          icon: 'fa fa-exclamation-circle',
          roles: "CLIENTLIST",
        },
      ]
    },
    {
      name: 'Agencies',
      url: '/agencies',
      icon: 'fa fa-building',
      roles: "PROFILES",
      children: [
        {
          name: 'List',
          url: '/agencies',
          icon: 'fa fa-list',
          roles: "AGENTS",
        }
      ]
    },

    {
      name: 'Users',
      url: '/users',
      icon: 'fa fa-user',
      roles: "USERS",
      children: [
        {
          name: 'List',
          url: '/userslist',
          icon: 'fa fa-users',
          roles: "USERS",
        }
      ]
    },
    {
      name: 'Billing',
      url: '/billing',
      icon: 'fa fa-money',
      roles: "BILLERTRANSACTIONS",
      children: [
        {
          name: 'Billers',
          url: '/billing/billers',
          icon: 'fa fa-dollar',
          roles: "BILLERADMIN",
        },
        {
          name: 'Transactions',
          url: '/billing/transactions',
          icon: 'fa fa-credit-card',
          roles: "BILLERTRANSACTIONS",
        }
      ]
    },
    {
      name: 'Media',
      url: '/media',
      icon: 'fa fa-film',
      roles: "MEDIAADMIN",
      children: [
        {
          name: 'All',
          url: '/media/all',
          icon: 'fa fa-camera',
          roles: "MEDIAADMIN"
        },
        {
          name: 'Creatives',
          url: '/media/creatives',
          icon: 'fa fa-image',
          roles: "CREATIVEADMIN"
        },
        {
          name: 'Links',
          url: '/media/links',
          icon: 'fa fa-link',
          roles: "LINKS",
        },
        {
          name: 'Placements',
          url: '/media/placements',
          icon: 'fa fa-bookmark',
          roles: "CREATIVEADMIN"
        },
        {
          name: 'Campaigns',
          url: '/media/campaigns',
          icon: 'fa fa-bookmark',
          roles: "CREATIVEADMIN"
        },
        {
          name: 'Ad Zones',
          url: '/media/zones',
          icon: 'fa fa-map-o',
          roles: "MEDIAADMIN"
        },
        {
          name: 'Channels',
          url: '/media/channels',
          icon: 'fa fa-tv',
          roles: "MEDIAADMIN"
        },
        {
          name: 'Banner Code',
          url: '/media/bannercode',
          icon: 'fa fa-code',
          roles: "MEDIAADMIN"
        },
      ]
    },
    {
      name: 'Websites',
      url: '/websites',
      icon: 'fa fa-chrome',
      roles: "DOMAINS",
      children: [
        {
          name: 'Pages',
          url: '/websites/pageslugs',
          icon: 'fa fa-globe',
          roles: "PAGESLUGS"
        },
        {
          name: 'Domains',
          url: '/websites/domains',
          icon: 'fa fa-list',
          roles: "DOMAINS"
        },
        {
          name: 'Template Sites',
          url: '/websites/sites',
          icon: 'fa fa-globe',
          roles: "SUPERADMIN"
        },
        {
          name: 'Redirects',
          url: '/websites/redirects',
          icon: 'fa fa-exchange',
          roles: "REDIRECTS",
          badge: {
            variant: 'info',
            text: 'New',
          }
        },
        {
          name: 'Html Blocks',
          url: '/websites/htmlblocks',
          icon: 'fa fa-cube',
          roles: "HTMLSECTIONS"
        }
      ]
    },
    {
      name: 'Mailings',
      url: '/mailings',
      icon: 'fa fa-inbox',
      roles: "MAILINGS",
      children: [
        {
          name: 'List',
          url: '/mailings/list',
          icon: 'fa fa-envelope',
          roles: "MAILINGS"
        },
      ]
    },

    {
      name: 'Blogs',
      url: '/blog/posts',
      icon: 'fa fa-rss-square',
      roles: "BLOGS"
    },
    {
      name: 'System',
      url: '/system',
      icon: 'fa fa-gears',
      roles: "SUPERADMIN",
      children: [
        {
          name: 'Server Status',
          url: '/system/status',
          icon: 'fa fa-server',
          roles: "SUPERADMIN"
        },
        {
          name: 'Data Caching',
          url: '/system/caching',
          icon: 'fa fa-database',
          roles: "SUPERADMIN"
        },
        {
          name: 'Bad Ips',
          url: '/system/badips',
          icon: 'fa fa-wifi',
          roles: "FIREWALL"
        },
        {
          name: 'Firewall Rules',
          url: '/system/firewallrules',
          icon: 'fa fa-fire',
          roles: "FIREWALL"
        },
        {
          name: 'Cron Jobs',
          url: '/system/crons',
          icon: 'fa fa-gears',
          roles: "CRONJOBS",
        },
      ]
    },
    {
      name: 'Other',
      url: '/other',
      icon: 'fa fa-gears',
      roles: "EVENTS",
      children: [
        {
          name: 'Events',
          url: '/other/events',
          icon: 'fa fa-balance-scale',
          roles: "EVENTS",
        },
        {
          name: 'Countries',
          url: '/other/countries',
          icon: 'fa fa-flag',
          roles: "COUNTRIES"
        }

      ]
    }
  ]
};
