import * as wConst from '../const/website';
import * as utils from "../utils";

const initState = {
  Loaded: false,
  ApiResult: {
    Code: 500, Message: ""
  },
  Data: {},
  UserFilters: {
    Loaded: false,
    Data: {}
  }
}
export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.ACTION_FETCH_CACHE_PENDING: {
      state = {...state, Loaded: false}
      break;
    }
    case wConst.ACTION_FETCH_CACHE_FAILED: {
      state = {...state, Loaded: true, ApiResult: action.payload}
      break;
    }
    case wConst.ACTION_FETCH_CACHE_SUCCESS: {
      state = {...state, Loaded: true, ApiResult: action.payload}
      let lst = state.ApiResult.Result;
      lst.map((v) => {
        const grp = v.Group;
        if(utils.isNullOrUndefined(state.Data[grp])) {
          state.Data[grp] = [];
        }
        state.Data[grp].push(v);
        return null;
      });
      break;
    }
    case wConst.FETCH_USERFILTERS_PENDING: {
      state = {...state, UserFilters: {Loaded: false, Data: {}}};
      break;
    }
    case wConst.FETCH_USERFILTERS_FAILED: {
      state = {...state, UserFilters: {Loaded: true, Data: action.payload}};
      break;
    }
    case wConst.FETCH_USERFILTERS_SUCCESS: {
      state = {...state, UserFilters: {Loaded: true, Data: action.payload}};
      break;
    }
    case wConst.FETCH_APPS_PENDING: {
      state = {...state};
      break;
    }
    case wConst.FETCH_APPS_FAILED: {
      state = {...state};
      break;
    }
    case wConst.FETCH_APPS_SUCCESS: {
      state = {...state};
      state.Data['AppNames'] = action.payload.Result;
      break;
    }
    case wConst.FETCH_ROLES_PENDING: {
      state = {...state};
      break;
    }
    case wConst.FETCH_ROLES_FAILED: {
      state = {...state};
      break;
    }
    case wConst.FETCH_ROLES_SUCCESS: {
      state = {...state};
      state.Data['Roles'] = action.payload.Result;
      break;
    }

    default: {}
  }

  return state;
}
