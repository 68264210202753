import * as pConst from '../const/profiles';
const initState = {
  ProfileList: {
    Loaded: false
  },
  Profiles: {
    Loaded: false
  },
  Groups: {
    Loaded: false
  },
  GroupProfiles: {
    Loaded: false,
    GroupId: 0
  },
  ClientList: {
    Loaded: false
  },
  PostClientList: {
    Posting: false,
    ApiResult: {Code: 500, Message: "New"}
  }
};

export default function reducer(state=initState, action) {
  switch(action.type) {
    case pConst.ACTION_POSTCLIENTLIST_PENDING: {
      state = {...state, PostClientList: {Posting: true}};
      break;
    }
    case pConst.ACTION_POSTCLIENTLIST_FAILED: {
      state = {...state, PostClientList: {Posting: false, ApiResult: action.payload}};
      break;
    }
    case pConst.ACTION_POSTCLIENTLIST_SUCCESS: {
      state = {...state, PostClientList: {Posting: false, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_CLIENTLIST_PENDING: {
      state = {...state, ClientList: {Loaded: false}};
      break;
    }
    case pConst.FETCH_CLIENTLIST_FAILED: {
      state = {...state, ClientList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_CLIENTLIST_SUCCESS: {
      state = {...state, ClientList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_PROFILES_PENDING: {
      state = {...state, ProfileList: {Loaded: false}};
      break;
    }
    case pConst.FETCH_PROFILES_FAILED: {
      state = {...state, ProfileList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_PROFILES_SUCCESS: {
      state = {...state, ProfileList: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_GROUPS_PENDING: {
      state = {...state, Groups: {Loaded: false}};
      break;
    }
    case pConst.FETCH_GROUPS_FAILED: {
      state = {...state, Groups: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_GROUPS_SUCCESS: {
      state = {...state, Groups: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_GROUPPROFILES_PENDING: {
      state = {...state, GroupProfiles: {Loaded: false, GroupId: action.payload.GroupId}};
      break;
    }
    case pConst.FETCH_GROUPPROFILES_FAILED: {
      state = {...state, GroupProfiles: {GroupId:state.GroupProfiles.GroupId, Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_GROUPPROFILES_SUCCESS: {
      state = {...state, GroupProfiles: {GroupId:state.GroupProfiles.GroupId, Loaded: true, ApiResult: action.payload}};
      break;
    }
    default: {}
  }
  return state;
}


