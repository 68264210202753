import React from 'react';
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout';
import {Container, Row} from "reactstrap";
import Loading from "./comps/loading";
import * as utils from "./utils";

function Loader() {
  return (
    <Loading/>
  );
}

const Redirects  = Loadable({
  loader: () => import('./views//Websites/Redirects'),
  loading: Loader,
});

const Domains  = Loadable({
  loader: () => import('./views//Websites/Domains'),
  loading: Loader,
});

const Domain  = Loadable({
  loader: () => import('./views//Websites/Domains/Domain'),
  loading: Loader,
});

const PageSlugs  = Loadable({
  loader: () => import('./views//Websites/PageSlugs'),
  loading: Loader,
});

const Media  = Loadable({
  loader: () => import('./views/Media/All'),
  loading: Loader,
});

const Creatives  = Loadable({
  loader: () => import('./views/Media/Creatives'),
  loading: Loader,
});

const Placements  = Loadable({
  loader: () => import('./views/Media/Placements'),
  loading: Loader,
});

const Zones  = Loadable({
  loader: () => import('./views/Media/Zones'),
  loading: Loader,
});

const BlogPosts  = Loadable({
  loader: () => import('./views/Blogs/Posts'),
  loading: Loader,
});
const BlogPost  = Loadable({
  loader: () => import('./views/Blogs/Post'),
  loading: Loader,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loader,
});

const Profiles = Loadable({
  loader: () => import('./views/Profiles'),
  loading: Loader,
});

const Groups = Loadable({
  loader: () => import('./views/Profiles/Groups/Groups'),
  loading: Loader,
});

const BlackList = Loadable({
  loader: () => import('./views/Profiles/BlackList/BlackList'),
  loading: Loader,
});

const GroupProfiles = Loadable({
  loader: () => import('./views/Profiles/Groups/GroupProfiles/GroupProfiles'),
  loading: Loader,
});

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loader,
});

const Agencies = Loadable({
  loader: () => import('./views/Agencies'),
  loading: Loader,
});

const SaveImage = Loadable({
  loader: () => import('./views/Media/SaveImage'),
  loading: Loader,
});

const HtmlBlocks = Loadable({
  loader: () => import('./views/Websites/HtmlBlocks'),
  loading: Loader,
});

const BannerCode = Loadable({
  loader: () => import('./views/Media/BannerCode'),
  loading: Loader,
});

const Links = Loadable({
  loader: () => import('./views/Media/Links'),
  loading: Loader,
});
const Link = Loadable({
  loader: () => import('./views/Media/Link'),
  loading: Loader,
});
const Campaigns = Loadable({
  loader: () => import('./views/Media/Campaigns'),
  loading: Loader,
});
const Countries = Loadable({
  loader: () => import('./views/Other/Countries'),
  loading: Loader,
});
const Channels = Loadable({
  loader: () => import('./views/Media/Channels'),
  loading: Loader,
});
const Billers = Loadable({
  loader: () => import('./views/Billing/Billers'),
  loading: Loader,
});

const Transactions = Loadable({
  loader: () => import('./views/Billing/Transactions'),
  loading: Loader,
});

const MailingList = Loadable({
  loader: () => import('./views/Mailings/List'),
  loading: Loader,
});
const Mailing = Loadable({
  loader: () => import('./views/Mailings/Mailing'),
  loading: Loader,
});
const ServerStatus = Loadable({
  loader: () => import('./views/System/Status'),
  loading: Loader,
});
const BadIps = Loadable({
  loader: () => import('./views/System/BadIps'),
  loading: Loader,
});
const FirewallRules = Loadable({
  loader: () => import('./views/System/FirewallRules'),
  loading: Loader,
});
const Cache = Loadable({
  loader: () => import('./views/System/Cache'),
  loading: Loader,
});

const Events = Loadable({
  loader: () => import('./views/Other/Events'),
  loading: Loader,
});
const Crons = Loadable({
  loader: () => import('./views/System/Crons'),
  loading: Loader,
});
const Sites = Loadable({
  loader: () => import('./views/Websites/Sites'),
  loading: Loader,
});


const routes = [
  { path: '/', name: 'Home', component: DefaultLayout, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/websites/domains', exact: true, name: 'Domains', component: Domains },
  { path: '/websites/domains/:id', name: 'Domain', component: Domain },
  { path: '/websites/htmlblocks', exact: true, name: 'HtmlBlocks', component: HtmlBlocks },
  { path: '/websites/pageslugs', exact: true, name: 'Page Slugs', component: PageSlugs },
  { path: '/websites/pageslugs/:domainid', name: 'Page Slugs', component: PageSlugs },
  { path: '/websites/sites', name: 'Template Sites', component: Sites },
  { path: '/websites/redirects', name: 'Redirects', component: Redirects },
  { path: '/media/all', exact: true, name: 'All Media', component: Media },
  { path: '/media/save', exact: true, name: 'Save Media', component: SaveImage },
  { path: '/media/creatives', exact: true, name: 'Creative Media', component: Creatives },
  { path: '/media/placements', exact: true, name: 'Placements', component: Placements },
  { path: '/media/placements/:type/:id', name: 'View Placements', component: Placements },
  { path: '/media/bannercode', name: 'Banner Code', component: BannerCode },
  { path: '/media/zones', exact: true, name: 'Zones', component: Zones },
  { path: '/userslist', exact: true,  name: 'Users', component: Users },
  { path: '/profileslist', exact: true,  name: 'Profiles', component: Profiles },
  { path: '/profiles/groups/',exact: true, name: 'Groups', component: Groups },
  { path: '/profiles/groups/:id', name: 'Group Profiles', component: GroupProfiles },
  { path: '/profiles/blacklist',exact: true, name: 'BlackList', component: BlackList },
  { path: '/blog/posts/', exact: true, name: 'Blog Posts', component: BlogPosts},
  { path: '/blog/post/:id', exact: true, name: 'Blog Post', component: BlogPost},
  { path: '/media/links', exact: true, name: 'Creative Links', component: Links},
  { path: '/media/link/:id', exact: true, name: 'Creative Link', component: Link},
  { path: '/media/campaigns', exact: true, name: 'Creative Campaigns', component: Campaigns},
  { path: '/other/countries', exact: true, name: 'Creative Countries', component: Countries},
  { path: '/media/channels', exact: true, name: 'Creative Channels', component: Channels},
  { path: '/billing/billers', exact: true, name: 'Billers', component: Billers},
  { path: '/billing/transactions', exact: true, name: 'Transactions', component: Transactions},
  { path: '/mailings/list', exact: true, name: 'Mailings List', component: MailingList},
  { path: '/mailings/:id', exact: true, name: 'Mailing', component: Mailing},
  { path: '/agencies', exact: true,  name: 'Agencies', component: Agencies },
  { path: '/system/status', exact: true,  name: 'Server Status', component: ServerStatus },
  { path: '/system/badips', exact: true,  name: 'Misbehaving Ip Address\'s', component: BadIps },
  { path: '/system/firewallrules', exact: true,  name: 'Firewall Rules', component: FirewallRules },
  { path: '/system/caching', exact: true,  name: 'Api/Site Caching', component: Cache },
  { path: '/other/events', exact: true, name: 'System Events', component: Events},
  { path: '/system/crons', exact: true, name: 'Cron Jobs', component: Crons},

];

export default routes;
