import * as wConst from '../const/website';
import * as pConst from "../const/profiles";
const initState = {
  Agencies: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
};
export default function reducer(state=initState, action) {
  switch(action.type) {

    case pConst.FETCH_AGENCIES_PENDING: {
      state = {...state, Agencies: {Loaded: false}};
      break;
    }
    case pConst.FETCH_AGENCIES_FAILED: {
      state = {...state, Agencies: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case pConst.FETCH_AGENCIES_SUCCESS: {
      state = {...state, Agencies: {Loaded: true, ApiResult: action.payload}};
      break;
    }

    default: {}
  }
  return state;
}

