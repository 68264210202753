import React, { Component } from 'react';
import {Container, Row} from "reactstrap";

class Loading extends Component {

  render() {
    return (
      <Container>
        <Row className="justify-content-center" style={{marginTop: "100px"}}>
          <img src={"/assets/img/loader.svg"} style={{width: "100px", height: "100px"}} alt={""}/>
        </Row>
      </Container>
    );
  }
}
export default Loading;
