import * as wConst from '../const/website';
const initState = {
  BlogPosts: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  },
  BlogPost: {
    Loaded: false,
    ApiResult: {Code: 500,Message: "New"}
  }
};
export default function reducer(state=initState, action) {
  switch(action.type) {
    case wConst.FETCH_BLOGPOSTS_PENDING: {
      state = {...state, BlogPosts: {...state.BlogPosts, Loaded: false}};
      break;
    }
    case wConst.FETCH_BLOGPOSTS_FAILED: {
      state = {...state, BlogPosts: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_BLOGPOSTS_SUCCESS: {
      state = {...state, BlogPosts: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_BLOGPOST_PENDING: {
      state = {...state, BlogPost: {...state.BlogPost, Loaded: false}};
      break;
    }
    case wConst.FETCH_BLOGPOST_FAILED: {
      state = {...state, BlogPost: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    case wConst.FETCH_BLOGPOST_SUCCESS: {
      state = {...state, BlogPost: {Loaded: true, ApiResult: action.payload}};
      break;
    }
    default: {}
  }
  return state;
}

